<template>
  <div>
    <banner-c></banner-c>
    <div class="gsjs">
      <div class="title-font-style">公司介绍</div>
      <div class="content">
        <div>
          <img :src="loadUrl.gsjs"/>
        </div>
        <div>
          <img class="logo" src="@/assets/images/logo.png" />
          <div class="desc">
            上海凝丰智能科技有限公司伴随着2016中国VR元年应势而生，作为国内元宇宙生态服务的先行者，持续深耕前沿科技产品趋势、技术、营销和服务，在整合提升产业链价值的过程中，已迭代为“数智化生态服务商”，为上下游企业提供所需的渠道开拓、产品分销、技术研发、数智化解决方案等服务，释放生态互联价值，成为企业实现数智化增长的可靠伙伴。
            凝丰智能总部位于上海，目前数智化营销渠道已覆盖全国，在元宇宙XR领域的市场份额和合作伙伴数量均处于国内领先地位，分销品类正向数智类全面扩容。其自研的元宇宙数实融合技术已申请多项国家专利和商标，以专业的硬件搭载领先的自研技术，面向工业、文娱、教育等各个领域提供一站式数智化前沿解决方案，
            让科技转化成现实价值。
          </div>
        </div>
      </div>
    </div>
    <div class="szlc-box">
      <div class="szlc">
        <img :src="loadUrl.fzlc_bg"/>
        <div class="title-font-style">发展历程</div>
        <div class="development-line">
          <div v-for="(item, index) in years" :key="index" :class="active_f_i == index ? 'active-f' : ''">
            <div @click="active_f_i = index"></div>
            <div @click="active_f_i = index">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="szlc-content">
        <div>{{ years[active_f_i].name }}</div>
        <div class="szlc-content-text">
          <div v-for="(item, index) in years[active_f_i].content" :key="index"
            :style="{ color: item.color || '#333', display: item.inlineBlock ? 'inline-block' : '' }">
            <span v-html="item.text"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="ryzz">
      <div class="title-font-style">荣誉资质</div>
      <div class="prev" @click="prevEvent"></div>
      <div class="next" @click="nextEvent"></div>
      <div class="ryzz-list">
        <div class="honors-box" :style="{ left: -(activeIndex - 1) * 353 + 'rem' }">
          <div v-for="(item, index) in showHonors" :key="index" :class="index == activeIndex ? 'active' : ''">
            <img :src="item.url" />
            <div>
              <div v-for="(e, i) in item.illustrate" :key="i">{{ e }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qywh">
      <img :src="loadUrl.qywh_bg"/>
      <div class="title-font-style">企业文化</div>
      <div class="qywh-content">
        <div>
          <div class="q1">
            <img :src="loadUrl.shiming" />
            <span>使命</span>
          </div>
          <div class="q2">推动元宇宙科学技术进步，促进社会数智化发展</div>
          <div class="q1">
            <img :src="loadUrl.yuanjing" />
            <span>愿景</span>
          </div>
          <div class="q2">成为全球最具价值元宇宙生态服务商</div>
          <div class="q1">
            <img :src="loadUrl.jiazhiguan" />
            <span>价值观</span>
          </div>
          <div class="q2">诚信守诺、持续奋斗、执行第一、成就员工、合作伙伴至上</div>
        </div>
        <div>
          <img :src="loadUrl.qywh"/>
        </div>
      </div>
    </div>
    <div class="lxwm">
      <div class="title-font-style">联系我们</div>
      <div class="lxwm-content">
        <div>
          <div class="s1">上海</div>
          <div class="s2" style="color: #D71819">上海总部</div>
          <div class="s3">上海市徐汇区钦州北路1001号光启园7号楼6层</div>
          <!-- <div class="s3">邮编：518054</div> -->
          <div class="s3">总机电话：400-900-6269</div>
        </div>
        <div>
          <div class="s1">北京</div>
          <div class="s2">北京分公司</div>
          <div class="s3">张僚</div>
          <!-- <div class="s3">邮编：518054</div> -->
          <div class="s3">18035958866</div>
        </div>
        <div>
          <div class="s1">西安</div>
          <div class="s2">西安分公司</div>
          <div class="s3">顾凯</div>
          <!-- <div class="s3">邮编：518054</div> -->
          <div class="s3">15214330809</div>
        </div>
      </div>
    </div>
    <div class="zmt">
      <div class="zmt-title">自媒体账号</div>
      <div class="zmt-content">
        <div>
          <img :src="loadUrl.gongzhonghao"/>
          <span>公众号</span>
        </div>
        <div>
          <img :src="loadUrl.shipinhao" />
          <span>视频号</span>
        </div>
        <div>
          <img :src="loadUrl.douyin" />
          <span>抖音</span>
        </div>
        <div>
          <img :src="loadUrl.xiaohongshu" />
          <span>小红书</span>
        </div>
        <div>
          <img :src="loadUrl.bilibili" />
          <span>bilibili</span>
        </div>
        <div>
          <img :src="loadUrl.weibo" />
          <span>微博</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import BannerC from '@/components/BannerC.vue';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const years: any = [
  {
    name: '2016',
    content: [
      {
        text: '2016是中国VR元年 凝丰智能应势而生',
      },
      {
        text: '瞄准前瞻市场快速推进业务'
      },
    ]
  },
  {
    name: '2017',
    content: [
      {
        text: '布局线上营销网络'
      },
      {
        text: '与当时VR领头羊品牌HTC VIVE合作签约'
      }
    ]
  },
  {
    name: '2018',
    content: [
      {
        text: '开始布局全国行业渠道网络'
      }
    ]
  },
  {
    name: '2019',
    content: [
      {
        text: '深度融合元宇宙生态'
      },
      {
        text: '为行业赋能解决方案新价值'
      }
    ]
  },
  {
    name: '2020',
    content: [
      {
        text: '跃居XR领域头部公司'
      },
      {
        text: '<span>与一线品牌商合作突破 </span><span class="blods">30家</span>',
      },
      {
        text: '<span>建立起渠道合作伙伴近 </span><span class="blods">1000家</span>',
      },
      {
        text: '<span>线上服务客户突破 </span><span class="blods">百万</span>',
      }
    ]
  },
  {
    name: '2021',
    content: [
      {
        text: '<span class="blods">整体营收破亿 </span><span>企业快速稳健发展</span>'
      },
      {
        text: '高速发展备受资本市场关注',
      },
      {
        text: '在行业数智化领域沉淀技术',
      }
    ]
  },
  {
    name: '2022',
    content: [
      {
        text: '成立研发团队'
      },
      {
        text: '<span>着重深耕 </span><span class="blods">数智化底层技术研发</span>'
      },
      {
        text: '同年6月 完成天使轮融资',
        color: '#D71819'
      }
    ]
  },
  {
    name: '2023',
    content: [
      {
        text: '元宇宙虚实融合应用平台（混空系统 Mixed Space）发布'
      },
      {
        text: '面向工业、文娱、教育提供成熟的数智化解决方案 促进元宇宙应用落地、为产业提质增效'
      },
      {
        text: '2023 上海最具投资潜力50佳创业企业',
        color: '#D71819'
      },
      {
        text: '“创客中国”元宇宙（人工智能）',
        inlineBlock: true
      },
      {
        text: '专业赛一等奖',
        color: '#D71819',
        inlineBlock: true
      }
    ]
  }
]
const showIndexs: any = ref([6, 0, 1, 2, 3])
let timer: any = null;
let timer2: any = null;
let clicks: any = false;
const honors = [
  {
    url: require('/public/images/about-us/ryzz1.png'),
    illustrate: ['2023上海最具投资潜力', '50佳创业企业']
  },
  {
    url: require('/public/images/about-us/ryzz2.png'),
    illustrate: ['“创客中国”元宇宙（人工智能）', '专业赛 一等奖']
  },
  {
    url: require('/public/images/about-us/ryzz3.png'),
    illustrate: ['2023数智创新优胜奖']
  },
  {
    url: require('/public/images/about-us/ryzz4.png'),
    illustrate: ['诺亦腾年度最佳合作伙伴']
  },
  {
    url: require('/public/images/about-us/ryzz5.png'),
    illustrate: ['VIVE2023年度销量金牌']
  },
  {
    url: require('/public/images/about-us/ryzz6.png'),
    illustrate: ['与圆周率达成全国战略合作']
  },
  {
    url: require('/public/images/about-us/ryzz7.png'),
    illustrate: ['上海市创新创业大赛 优胜奖']
  },
]
const active_f_i = ref(years.length - 1)
const active_r_i = ref(1)
const showHonors: any = ref([])
const activeIndex = ref(2)

const prevEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.honors-box') as any).style.transition = 'all .5s';
  (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all .5s'
  });
  if (active_r_i.value != 0) {
    active_r_i.value -= 1;
  } else {
    active_r_i.value = honors.length - 1
  }
  activeIndex.value -= 1;
}

const nextEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.honors-box') as any).style.transition = 'all .5s';
  (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all .5s'
  });
  if (active_r_i.value != honors.length - 1) {
    active_r_i.value += 1;
  } else {
    active_r_i.value = 0
  }
  activeIndex.value += 1;
}

onMounted(async () => {
  showHonors.value = showIndexs.value.map((k: number) => honors[k]);
  clearInterval(timer)
  timer = setInterval(() => {
    nextEvent()
  }, 3000)

  const shiming: any = await asynchronousRetrieval('nfznxr-images/about-us/shiming.png');
  const yuanjing: any = await asynchronousRetrieval('nfznxr-images/about-us/yuanjing.png');
  const jiazhiguan: any = await asynchronousRetrieval('nfznxr-images/about-us/jiazhiguan.png');
  const gongzhonghao: any = await asynchronousRetrieval('nfznxr-images/about-us/gongzhonghao.png');
  const shipinhao: any = await asynchronousRetrieval('nfznxr-images/about-us/shipinhao.png');
  const douyin: any = await asynchronousRetrieval('nfznxr-images/about-us/douyin.png');
  const xiaohongshu: any = await asynchronousRetrieval('nfznxr-images/about-us/xiaohongshu.png');
  const bilibili: any = await asynchronousRetrieval('nfznxr-images/about-us/bilibili.png');
  const weibo: any = await asynchronousRetrieval('nfznxr-images/about-us/weibo.png');
  const gsjs: any = await asynchronousRetrieval('nfznxr-images/about-us/gsjs.png');
  const fzlc_bg: any = await asynchronousRetrieval('nfznxr-images/about-us/fzlc-bg.png');
  const development_line: any = await asynchronousRetrieval('nfznxr-images/about-us/development-line.png');
  const qywh_bg: any = await asynchronousRetrieval('nfznxr-images/about-us/qywh-bg.png');
  const qywh: any = await asynchronousRetrieval('nfznxr-images/about-us/qywh.png');
  loadUrl.value = { shiming, yuanjing, jiazhiguan, gongzhonghao, shipinhao, douyin, xiaohongshu, bilibili, weibo, gsjs, fzlc_bg, development_line, qywh_bg, qywh }
})

onUnmounted(() => {
  clearInterval(timer)
  clearInterval(timer2)
})

watch(active_r_i, (newState) => {
  clearInterval(timer)
  showIndexs.value = showIndexs.value.map((v: number, i: number) => {
    if (i < 2) {
      if ((newState - (2 - i)) >= 0) {
        return newState - (2 - i)
      } else {
        return (honors.length + newState) - (2 - i)
      }
    }
    if (i == 2) return newState
    if (i > 2) {
      if ((newState < honors.length - 2) || (newState + (i - 2) < honors.length)) {
        return newState + (i - 2)
      } else {
        return newState + (i - 2) - honors.length
      }
    }
  });
  clearTimeout(timer2)
  timer2 = setTimeout(() => {
    (document.querySelector('.honors-box') as any).style.transition = 'none';
    (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
      ele.style.transition = 'none'
    });
    showHonors.value = showIndexs.value.map((k: number) => honors[k]);
    activeIndex.value = 2
    clicks = false
    clearInterval(timer)
    timer = setInterval(() => {
      nextEvent()
    }, 3000)
  }, 500)
})
</script>
<style scoped lang="less">
.gsjs {
  margin-top: 40rem;

  .content {
    height: 347rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80rem 0 100rem;

    &>div {
      margin: 0 15rem;
    }

    &>div:first-child {
      width: 547rem;
      height: 347rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &>div:last-child {
      width: 623rem;
      height: 347rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      text-align: left;
      line-height: 30rem;
      text-align: justify;

      .logo {
        width: 145rem;
        height: 64rem;
        transform: translateY(-11px);
      }
      .desc {
        transform: translateY(-8px);
      }
    }
  }
}

.szlc-box {
  background-color: #F7F7F7;
  overflow: hidden;
  margin: 100rem 0;

  .szlc {
    position: relative;
    width: 100%;
    height: 426rem;
    padding: 100rem 0 154rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 80rem;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      z-index: 1;
    }

    .title-font-style {
      color: #fff;
    }

    .development-line {
      width: 1241rem;
      height: 2rem;
      background: url(/public/images/about-us/development-line.png);
      background-size: 100% 100%;
      padding: 0 190rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 24rem;
      font-family: PingFangSC-Medium;

      &>div {
        position: relative;
        color: #CF6464;

        &>div {
          position: absolute;
        }

        &>div:first-child {
          position: relative;
          position: absolute;
          left: -8rem;
          top: -8rem;
          width: 16rem;
          height: 16rem;
          background-color: #CF6464;
          border-radius: 100%;
          cursor: pointer;
        }

        &:hover,
        &.active-f {
          color: #D71819;

          &>div:first-child {
            background-color: #D71819;
          }

          &>div:first-child::after {
            content: '';
            position: absolute;
            left: calc(-9rem - 1px);
            top: calc(-9rem - 1px);
            width: 34rem;
            height: 34rem;
            border: 1px solid #D71819;
            border-radius: 50%;
          }
        }

        &>div:last-child {
          width: 54rem;
          height: 33rem;
          top: 37.5rem;
          left: -27rem;
          cursor: pointer;
        }
      }
    }
  }

  .szlc-content {
    width: 1080rem;
    display: inline-block;
    font-size: 0;

    &>div:first-child {
      padding: 0 0 30rem 100rem;
      font-weight: 600;
      font-size: 28rem;
      color: #D71819;
      font-family: PingFangSC-Medium;
      text-align: left;
      border-bottom: 1px solid #D5D5D5;
    }

    &>div:last-child {
      padding: 30rem 190rem 100rem 100rem;
      box-sizing: border-box;
    }

    .szlc-content-text {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      text-align: left;
      line-height: 40rem;
      white-space: pre;
      font-weight: 500;
      font-family: PingFangSC-Medium;
    }
  }
}

.ryzz {
  position: relative;
  width: 1098rem;
  display: inline-block;

  .ryzz-list {
    position: relative;
    width: 100%;
    height: 400rem;
    margin-top: 55rem;
    margin-bottom: 200rem;
    overflow: hidden;


    .honors-box {
      position: absolute;
      top: 0;
      width: 1804rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {
        position: relative;
        width: 343rem;
        height: 350rem;
        border-radius: 8rem;
        padding-top: 10rem;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid #D8D8D8;

        img {
          width: 217rem;
          height: 224rem;
        }

        &>div {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 87rem;
          padding: 15rem 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          background-color: #D71819;
          font-weight: bold;
          font-size: 20rem;
          color: #FFFFFF;
          line-height: 28rem;
          border-radius: 0 0 8rem 8rem;
        }
      }

      .active {
        transform: scale(1.14);
        margin: 24.5rem;
        box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 292rem;
    width: 50rem;
    height: 50rem;
    padding: 0;
    cursor: pointer;
  }

  .prev {
    background: url(/public/images/left.png);
    background-size: 100% 100%;
    left: -60rem;
  }

  .next {
    background: url(/public/images/right.png);
    background-size: 100% 100%;
    right: -60rem;
  }
}

.qywh {
  position: relative;
  width: 100%;
  height: 35.64vw;
  padding-top: 20rem;
  box-sizing: border-box;

  &>img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .qywh-content {
    position: relative;
    width: 1200rem;
    height: 400rem;
    display: inline-block;
    font-size: 0;
    background-color: #F7F7F7;
    border-radius: 8rem;
    margin-top: 80rem;
    overflow: hidden;

    &>div:first-child {
      height: 400rem;
      padding: 60rem 0 60rem 60rem;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      text-align: left;

      .q1 {
        font-weight: 600;
        font-size: 24rem;
        color: #D71819;
        font-family: PingFangSC-Medium;
        margin-bottom: 10rem;

        img {
          width: 30rem;
          height: 30rem;
          vertical-align: middle;
          margin-right: 11rem;
        }

        span {
          vertical-align: middle;
        }
      }

      .q2 {
        padding-left: 41rem;
        margin-bottom: 30rem;
      }
    }

    &>div:last-child {
      width: 600rem;
      height: 400rem;
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.lxwm {
  position: relative;
  margin-top: 100rem;
  padding-bottom: 257rem;

  .lxwm-content {
    width: 1040rem;
    height: 177rem;
    font-weight: 400;
    font-size: 14rem;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    position: absolute;
    left: 50%;
    margin-left: -520rem;
    top: 164rem;

    .s1 {
      font-weight: 600;
      font-size: 24rem;
      color: #0A0A0A;
      font-family: PingFangSC-Medium;
      margin-bottom: 9rem;
    }

    .s2 {
      font-weight: 600;
      font-size: 16rem;
      color: #0A0A0A;
      font-family: PingFangSC-Medium;
      margin-bottom: 20rem;
    }

    .s3 {
      font-weight: 400;
      font-size: 14rem;
      color: #333333;
      line-height: 20rem;
      margin-bottom: 16rem;
    }
  }
}

.zmt {
  position: relative;
  margin-top: 80rem;
  padding-bottom: 299rem;

  .zmt-title {
    font-weight: 600;
    font-size: 24rem;
    color: #333333;
    font-family: PingFangSC-Medium;
    margin-bottom: 34rem;
  }

  .zmt-content {
    width: 1200rem;
    height: 233rem;
    background: rgba(215, 24, 25, 0.03);
    border-radius: 8rem;
    margin-bottom: 66rem;
    padding: 0 160rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    margin-left: -600rem;

    &>div {
      height: 150rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14rem;
      color: #333333;

      img {
        width: 113rem;
        height: 113rem;
      }
    }
  }
}
</style>